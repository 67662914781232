<template>
  <div>
    <!-- <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            Acceso al Sistema
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="username"
            autofocus
            color="accent"
            label="Username"
            required
          >
          </v-text-field>
          <v-text-field
            v-model="password"
            type="password"
            color="accent"
            label="Password"
            @keyup.enter="ingresar()"
            required
          >
          </v-text-field>
          <v-flex class="red--text" v-if="errorM">
            {{ errorM }}
          </v-flex>
        </v-card-text>
        <v-card-actions class="px-3 pb-3">
          <v-flex text-xs-right>
            <ButtonApp @click="login">Login with google</ButtonApp>
          </v-flex>
        </v-card-actions>
      </v-card> -->
    <div class="login-box">
      <img
        src="../assets/logotipo-default.png"
        class="avatar"
        alt="Avatar Image"
      />
      <h1>Login</h1>
      <form @submit.prevent="login">
        <!-- USERNAME INPUT -->
        <label for="username">Username</label>
        <input v-model="username" type="text" />
        <!-- PASSWORD INPUT -->
        <label for="password">Password</label>
        <input v-model="username" type="password" />
        <div class="button">
          <input type="submit" value="Log In">
        </div>
        <a href="#">Lost your Password?</a><br />
        <a href="#">Don't have An account?</a>
      </form>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import { mapActions } from "vuex";
import router from "../router";
import axios from "axios";

export default {
  data() {
    return {
      username: "tedescodev",
      password: "222324",
      errorM: null,
    };
  },
  methods: {
    ...mapActions("usersNamespace", ["guardarToken"]),
    login: async function() {
      //eslint-disable-next-line
      await axios
        .post("auth/signin", {
          username: this.username,
          password: this.password,
        })
        .then((respuesta) => {
          return respuesta.data;
        })
        .then((data) => {
          swal({
            title: "Great!",
            text: "Bienvenido a House Buy App",
            icon: "success",
          });
          this.guardarToken(data.token);
          router.push({ name: "Home" });
        })
        .catch((error) => {
          console.log(error);
          this.errorM = null;
          if (error.response.status == 404) {
            this.errorM =
              "No exíste el usuario o las credenciales son incorrectas";
          } else {
            this.errorM = "Ocurrió un error con el servidor";
          }
        });
    },
  },
};
</script>
}
<style lang="css">
body {
  margin: 0;
  padding: 0;
  background: url(../assets/bg.jpg) no-repeat center top;
  background-size: cover;
  font-family: sans-serif;
  height: 100vh;
}

.login-box {
  width: 320px;
  height: 420px;
  background: #000;
  border-radius: 10px;
  color: #fff;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  padding: 70px 30px;
}

.login-box .avatar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: absolute;
  top: -50px;
  left: calc(50% - 50px);
}

.login-box h1 {
  margin: 0;
  padding: 0 0 20px;
  text-align: center;
  font-size: 22px;
}

.login-box label {
  margin: 0;
  padding: 0;
  font-weight: bold;
  display: block;
}

.login-box input {
  width: 100%;
  margin-bottom: 20px;
}

.login-box input[type="text"],
.login-box input[type="password"] {
  border: none;
  border-bottom: 1px solid #fff;
  background: transparent;
  outline: none;
  height: 40px;
  color: #fff;
  font-size: 16px;
}

.login-box input[type="submit"] {
  border: none;
  outline: none;
  height: 40px;
  background: rgba(70, 208, 218);
  color: #fff;
  font-size: 18px;
  border-radius: 20px;
}

.login-box input[type="submit"]:hover {
  cursor: pointer;
  background: #ffc107;
  color: #000;
}

.login-box a {
  text-decoration: none;
  font-size: 12px;
  line-height: 20px;
  font-weight: bold;
  color: darkgrey;
}

.login-box .button {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.login-box a:hover {
  color: #fff;
}
</style>
